import { Box, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function QueuesFilter({ onFiltered, ...rest }) {
  const [queues, setQueues] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadQueues();
    }
    fetchData();
  }, []);

  const loadQueues = async () => {
    try {
      const { data } = await api.get("/queue");
      const queueList = data.map((q) => ({ id: q.id, name: q.name }));
      setQueues(queueList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value) => {
    setSelecteds(value);
    onFiltered(value);
  };

  return (
    <Autocomplete
      {...rest}
      multiple
      size="small"
      options={queues}
      value={selecteds}
      onChange={(e, v, r) => onChange(v)}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => {
        return (
          option?.id === value?.id ||
          option?.name.toLowerCase() === value?.name.toLowerCase()
        );
      }}
      renderTags={(value, getProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            style={{
              backgroundColor: "#bfbfbf",
              textShadow: "1px 1px 1px #000",
              color: "white",
            }}
            label={option.name}
            {...getProps({ index })}
            size="small"
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Filtro por Fila"
        />
      )}
    />
  );
}
